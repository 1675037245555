<template>
  <div class="add-regional-committee-announcement add-template">
    <g-background>
      <page-header>
        <h1>{{ translations.tcAddRegionalCommitteeAnnouncement }}</h1>
      </page-header>

      <page-body>
        <div class="page-navigation">
          <div class="nav-item nav-item-1" :class="{ active: page > 0 }">
            <div class="icon">
              <geo-dis />
            </div>
            <div class="text">{{ translations.tcGeographicDistribution }}</div>
          </div>
          <div class="sep" :class="{ active: page > 1 }"></div>
          <div class="nav-item nav-item-2" :class="{ active: page > 1 }">
            <div class="icon">
              <audience-icon />
            </div>
            <div class="text">{{ translations.tcAudience }}</div>
          </div>
          <div class="sep" :class="{ active: page > 2 }"></div>
          <div class="nav-item nav-item-3" :class="{ active: page > 2 }">
            <div class="icon">
              <message-icon />
            </div>
            <div class="text">{{ translations.tcEnterMessage }}</div>
          </div>
          <div class="sep" :class="{ active: page > 3 }"></div>
          <div class="nav-item nav-item-4" :class="{ active: page > 3 }">
            <div class="icon">
              <review-icon />
            </div>
            <div class="text">{{ translations.tcReviewAndSubmit }}</div>
          </div>
        </div>
      </page-body>
      <hr />
      <b-form @submit.prevent v-if="page === 1" class="view-1 g-mb-2">
        <h2 class="h-style-1 mb-3">
          {{ translations.tcDistributionLevel }}
          <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcDistributionLevel">
            <i-tooltip />
          </span>
        </h2>
        <div class="g-mb-1">
          <b-button-group>
            <b-button
              class="btn-w190"
              :variant="cmt_rgn_both === 0 ? 'primary' : 'outline'"
              @click="handleDistributionButtonClick(0)"
              >{{ translations.tcCommitteeOnly }}</b-button
            >
            <b-button
              class="btn-w190"
              :variant="cmt_rgn_both === 1 ? 'primary' : 'outline'"
              @click="handleDistributionButtonClick(1)"
              >{{ translations.tcRegion }}</b-button
            >
            <b-button
              class="btn-w190"
              :variant="cmt_rgn_both === 2 ? 'primary' : 'outline'"
              @click="handleDistributionButtonClick(2)"
              >{{ translations.tcBoth }}</b-button
            >
          </b-button-group>
        </div>
        <div class="areas">
          <h2 class="h-style-1 mb-3">{{ translations.tcAreas }}</h2>
          <div class="checkbox-row">
            <div
              v-if="(cmt_rgn_both === 2 || cmt_rgn_both === 0) && !!userCommittees && userCommittees.areas.length > 0"
            >
              <GeographicAreas
                :geo_type="`rcMembers`"
                :area_prop="userCommittees"
                :title_override="getRegionalCommitteeTitle"
              ></GeographicAreas>
            </div>
            <div v-show="cmt_rgn_both >= 1">
              <GeographicAreas
                :geo_type="`countries`"
                :area_prop="committeeCountries"
                :title_override="translations.tcRegion"
                @checkboxChanged="handleCheckboxChange"
              ></GeographicAreas>
            </div>
          </div>
        </div>
      </b-form>
      <b-form @submit.prevent v-if="page === 2" class="view-2 g-mb-2">
        <h2 class="h-style-1 mb-3">
          {{ translations.tcAudience }}
          <span
            class="i-tooltip"
            v-b-tooltip.hover
            :title=translations.tcTooltipAnnouncementAimedAt
          >
            <i-tooltip />
          </span>
        </h2>
        <div class="g-mb-1">
          <b-button-group>
            <b-button
              @click="filterItems('Management', null)"
              :variant="l_recipients === 'Management' ? 'primary' : 'outline-primary'"
              >{{ translations.tcCountryLeadership }}</b-button
            >
            <b-button
              @click="filterItems('Camp', null)"
              :variant="l_recipients === 'Camp' ? 'primary' : 'outline-primary'"
              >{{ translations.tcCampOfficerGroups }}</b-button
            >
            <b-button
              @click="filterItems('Member', null)"
              :variant="l_recipients === 'Member' ? 'primary' : 'outline-primary'"
              >{{ translations.tcMembers }}</b-button
            >
          </b-button-group>
        </div>
        <h2 class="h-style-1 mb-3">{{ translations.tcMemberType }}</h2>
        <div class="d-flex g-mb-1 member-type">
          <b-button-group>
            <b-button
              @click="filterItems(null, 'Both')"
              :variant="g_recipients === 'Both' ? 'primary' : 'outline-primary'"
              >{{ translations.tcBoth }}</b-button
            >
            <b-button
              @click="filterItems(null, 'Gideon')"
              :variant="g_recipients === 'Gideon' ? 'primary' : 'outline-primary'"
              >{{ translations.tcGideon }}</b-button
            >
            <b-button
              @click="filterItems(null, 'Auxiliary')"
              :variant="g_recipients === 'Auxiliary' ? 'primary' : 'outline-primary'"
              >{{ translations.tcAuxiliary }}</b-button
            >
          </b-button-group>
          <b-form-checkbox
            v-if="l_recipients === 'Camp'"
            v-model="include_state_elected_officers"
            name="include-elected"
            inline
            >{{ translations.tcIncludeNationalLeadershipTeam }}</b-form-checkbox
          >
        </div>
        <span v-if="l_recipients === 'Camp'">
          <h2 class="h-style-1 mb-3">{{ leadership_title }}</h2>
          <div class="checkbox-row">
            <div v-for="(items, lx) in filteredItems.items" :key="lx">
              <GeographicAreas :geo_type="filteredItems.type" :area_prop="items"></GeographicAreas>
            </div>
          </div>
        </span>
      </b-form>
      <b-form @submit.prevent v-if="page === 3" class="view-3 g-mb-2">
        <AnnouncementTemplate
          :form="form"
          @announcement_validated="handleNextClick"
          @announcement_cancel="handleCancelClick"
          @backClick="handleBackClick"
          :include="include_state_elected_officers"
          :nextButtonText="translations.tcNext"
        />
      </b-form>
      <div v-if="page === 4">
        <AnnouncementReview
          :includeOfficers="include_state_elected_officers"
          :audienceDistribution="audienceDistribution"
          :selectedCommittees="selectedCommittees"
          :selectedUserCommittees="selectedUserCommittees"
          :cmt_rgn_both="cmt_rgn_both"
        ></AnnouncementReview>
      </div>
      <section v-if="page !== 3" class="form-buttons">
        <b-button variant="primary" class="mr-3" v-if="page > 1 && page < 4" @click="handleBackClick">
          {{ translations.tcBack }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page < 4" @click="handleNextClick">
          {{ translations.tcNext }}
        </b-button>
        <b-button variant="tertiary" v-if="page < 4" @click="handleCancelClick">
          {{ translations.tcCancel }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page === 4" @click="handleSubmit">
          {{ translations.tcSubmit }}
        </b-button>
        <b-button variant="tertiary" v-if="page === 4" @click="page = 1">
          {{ translations.tcEdit }}
        </b-button>
      </section>
    </g-background>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnouncementReview from '@/components/AnnouncementReview.vue'
import AnnouncementTemplate from '@/components/AnnouncementTemplate.vue'
import audienceIcon from '@/assets/svgs/audience-icon.vue'
import constantData from '@/json/data.json'
import gBackground from '@/components/gBackground.vue'
import GeoDis from '@/assets/svgs/geographic-distribution-icon.vue'
import GeographicAreas from '@/components/GeographicAreas.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import messageIcon from '@/assets/svgs/message-icon.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-regional-committee-announcement',
  mixins: [translationMixin],
  data() {
    return {
      all_compare: true,
      cabinetTypes: {
        'Auxiliary Areas': 'tcAuxiliaryAreas',
        'Auxiliary Camp Cabinet': 'tcAuxiliaryCampCabinet',
        'Auxiliary Members': 'tcAuxiliaryMembers',
        'Auxiliary State Leadership': 'tcAuxiliaryStateLeadership',
        'Gideon Areas': 'tcGideonAreas',
        'Gideon Camp Cabinet': 'tcGideonCampCabinet',
        'Gideon Members': 'tcGideonMembers',
        'Gideon State Leadership': 'tcGideonStateLeadership'
      },
      cmt_rgn_both: 2,
      filteredItems: {
        type: '',
        items: [],
      },
      form: {},
      g_recipients: 'Both',
      include_state_elected_officers: null,
      isGeographySet: false,
      l_recipients: 'Management',
      leadership_title: '',
      localeAndMemberTypes: [
        'campAllLeaders',
        'campAuxiliaryLeaders',
        'campGideonLeaders',
        'countryAuxiliaryManagement',
        'countryBothManagement',
        'countryGideonManagement',
        'stateAuxiliaryMembers',
        'stateBothMembers',
        'stateGideonMembers'
      ],
      page: 1,
      selectedCommittees: {},
      translations: {},
    }
  },
  watch: {},
  async created() {
    try {
      await Promise.all([
        await this.setLoadingStatus(true),
        await this.getViewTranslations('cabinet-types'),
        await this.getComponentTranslations('common.officer-titles'),
        await this.preEdit(),
        await this.getRegionalCommitteeMembers(),
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)

        this.translateAreaItems('userCommittees', 'All Members')
        this.translateAreaItems('committeeCountries', 'All Countries')
        this.markUserCommitteesAsSelected()
        this.translateOfficerText()

      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      announcementForm: 'announcements/announcementForm',
      committeesList: 'announcements/committeesList',
      campAllLeaders: 'announcements/campAllLeaders',
      campAuxiliaryLeaders: 'announcements/campAuxiliaryLeaders',
      campGideonLeaders: 'announcements/campGideonLeaders',
      committeeCountries: 'announcements/committeeCountries',
      countryAuxiliaryManagement: 'announcements/countryAuxiliaryManagement',
      countryBothManagement: 'announcements/countryBothManagement',
      countryGideonManagement: 'announcements/countryGideonManagement',
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      regionalCommitteeKey: 'announcements/regionalCommitteeKey',
      selectedAllCommitteeCountries: 'announcements/selectedAllCommitteeCountries',
      selectedCommitteeCountries: 'announcements/selectedCommitteeCountries',
      selectedCommitteeMembers: 'announcements/selectedCommitteeMembers',
      selectedUserCommittees: 'announcements/selectedUserCommittees',
      stateAuxiliaryMembers: 'announcements/stateAuxiliaryMembers',
      stateBothMembers: 'announcements/stateBothMembers',
      stateGideonMembers: 'announcements/stateGideonMembers',
      userCommittees: 'announcements/userCommittees',
      userId: 'user/userId',
    }),
    getRegionalCommitteeTitle() {
      if (!!this.userCommittees && !!this.userCommittees.title) {
        return this.userCommittees.title.replace(' Members', '')
      } else {
        return 'Committee'
      }
    },
    isCountrySelected() {
      if (
        this.committeeCountries.areas.filter((ccf) => {
          return ccf.selected === true
        }).length > 0
      ) {
        return true
      } else {
        return false
      }
    },
    hasWholeCabinet() {
      return this.l_recipients === 'Camp'
    },
    audienceDistribution() {
      let aud_dist = this.filteredItems.items.map((im) => {
        return {
          title: im.title,
          key: im.key,
          areas: im.areas.filter((af) => af.selected === true || af.selected === 'true'),
        }
      })
      if (!!this.selectedCommitteeMembers.areas && this.selectedCommitteeMembers.areas.length > 0) {
        aud_dist = [...aud_dist, ...[this.selectedCommitteeMembers]]
      }
      return aud_dist
    },
    checkedAudience() {
      let checked_areas = this.audienceDistribution.length > 0 ? 0 : -1
      this.audienceDistribution.map((ad) => {
        ad.areas.map((ada) => {
          if (ada.selected) {
            checked_areas++
          }
        })
      })
      return checked_areas
    },
    gideonManagement() {
      if (this.filteredItems.type.includes('Management')) {
        if (this.filteredItems.type.includes('Both') || this.filteredItems.type.includes('Gideon')) {
          return true
        }
      }
      return false
    },
    auxiliaryManagement() {
      if (this.filteredItems.type.includes('Management')) {
        if (this.filteredItems.type.includes('Both') || this.filteredItems.type.includes('Auxiliary')) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      addUpdateAnnouncementMessage: 'announcements/addUpdateAnnouncementMessage',
      getCommitteeMembers: 'announcements/getCommitteeMembers',
      getRegionalCommitteeMembers: 'announcements/getRegionalCommitteeMembers',
      markUserCommitteesAsSelected: 'announcements/markUserCommitteesAsSelected',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMembers: 'announcements/setMembers',
      setMessagePackageAudience: 'announcements/setMessagePackageAudience',
      setMessagePackageGeography: 'announcements/setMessagePackageGeography',
      setMessagePackageMessage: 'announcements/setMessagePackageMessage',
    }),
    handleBackClick() {
      if (this.page !== 3 || this.isCountrySelected) {
        this.page--
      } else {
        this.page = 1
      }
    },
    handleDistributionButtonClick(dist) {
      this.cmt_rgn_both = dist
    },
    async preEdit() {
      await (async () => {
        this.form.msg_message_id = this.announcementForm.msg_message_id
        this.form.editing = this.announcementForm.editing
        this.form.msg_member_type = this.announcementForm.msg_member_type
        this.form.notification = this.announcementForm.notification
        this.form.title = this.announcementForm.title
        this.form.expires = this.announcementForm.expires
        this.form.message = this.announcementForm.message
        this.form.fileArray = this.announcementForm.fileArray
        this.form.tempFileArray = this.announcementForm.tempFileArray
        this.presetDate =
          this.announcementForm.expires === null || this.announcementForm.expires.toString().includes('0001')
            ? null
            : this.announcementForm.expires
      })()
      if (this.announcementForm.editing) {
        let _g =
          this.announcementForm.msg_member_type === 'a'
            ? 'Auxiliary'
            : this.announcementForm.msg_member_type === 'g'
            ? 'Gideon'
            : 'Both'
        let _l =
          this.announcementForm.type.findIndex((aft) => aft.includes('Management')) > -1
            ? 'Management'
            : this.announcementForm.type.findIndex((aft) => aft.includes('Camp')) > -1
            ? 'Camp'
            : 'Member'
        this.filterItems(_l, _g)
      }
      this.selectedCommittees = this.selectedCommitteeCountries

      this.include_state_elected_officers = this.announcementForm.includeElected || false
    },
    async preFilter(l, g) {
      this.l_recipients = l || this.l_recipients
      this.g_recipients = g || this.g_recipients
      await this.setMembers(this.g_recipients)
      this.filteredItems.items = []
      this.filteredItems.type = `${g || this.g_recipients}${l || this.l_recipients}`
      this.leadership_title = (l || this.l_recipients) === 'Camp' ? this.translations.tcOfficerGroups : ''
    },
    async filterItems(l, g) {
      await this.preFilter(l, g)
      this.filteredItems.items = await (async () => {
        switch (this.filteredItems.type) {
          case 'AuxiliaryCamp':
            return this.campAuxiliaryLeaders
          case 'AuxiliaryManagement':
            return this.countryAuxiliaryManagement
          case 'AuxiliaryMember':
            return this.stateAuxiliaryMembers
          case 'AuxiliaryState':
            return this.stateAuxiliaryLeaders
          case 'BothCamp':
            return this.campAllLeaders
          case 'BothManagement':
            return this.countryBothManagement
          case 'BothMember':
            return this.stateBothMembers
          case 'BothState':
            return this.stateAllLeaders
          case 'GideonCamp':
            return this.campGideonLeaders
          case 'GideonManagement':
            return this.countryGideonManagement
          case 'GideonMember':
            return this.stateGideonMembers
          case 'GideonState':
            return this.stateGideonLeaders
          default:
            return this.l === 'Camp' ? this.campAllLeaders : this.l === 'State' ? this.stateAllLeaders : []
        }
      })()
    },
    async handleCancelClick() {
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleCheckboxChange() {
      let selectedCommitteeKeys = await this.selectedCommitteeCountries.areas.map((sk) => sk.value)
      // selecting 'all committees' needs to be the same as selecting them all individually
      if (
        !!selectedCommitteeKeys &&
        selectedCommitteeKeys.length === 1 &&
        selectedCommitteeKeys[0] === constantData.empty_guid
      ) {
        selectedCommitteeKeys = await this.selectedAllCommitteeCountries.areas.map((sk) => sk.value)
        this.selectedCommittees = this.selectedAllCommitteeCountries
      } else {
        this.selectedCommittees = this.selectedCommitteeCountries
      }
    },
    async handleSubmit() {
      try {
        this.setLoadingStatus(true)
        await this.setMessagePackageMessage({
          user: this.userId,
          sendername: this.demograph.informal_name + ' ' + this.demograph.last_name,
          senderemail: this.demograph.email,
        })

        // Can set location for multiple countries
        await (async () => {
          this.selectedCommitteeCountries.areas.map((ssra, ix) => {
            this.isGeographySet = true
            this.setMessagePackageGeography({
              statekey: this.regionalCommitteeKey,
              location: ssra.value,
              location_name: ssra.text,
              location_all: ssra.value === constantData.empty_guid,
              type: 'RCommittee',
              user: this.userId,
              ordinal: ix,
            })
          })
        })()

        await (async () => {
          this.selectedUserCommittees.areas.map((ci, cix) => {
            this.setMessagePackageAudience({
              audience: ci.value,
              type: 'RCMember',
              user: this.userId,
              ordinal: cix,
            })
          })
        })()

        await (async () => {
          if (this.gideonManagement) {
            this.setMessagePackageAudience({
              audience: 'ABDA920A-F939-4DE0-BAD5-0AB91F476B5E',
              type: 'Management',
              user: this.userId,
              ordinal: 1,
            })
          }
          if (this.auxiliaryManagement) {
            this.setMessagePackageAudience({
              audience: '678CFA9F-3CED-4880-AB74-6BAD634AAF74',
              type: 'Management',
              user: this.userId,
              ordinal: 2,
            })
          }
          if (!this.gideonManagement && !this.auxiliaryManagement && this.isGeographySet) {
            this.filteredItems.items.map((fi) => {
              fi.areas.map((fm, fmx) => {
                if (fm.selected) {
                  this.setMessagePackageAudience({
                    audience: fm.value,
                    type: this.l_recipients,
                    user: this.userId,
                    ordinal: fmx,
                  })
                }
              })
            })
          }
        })()

        if (!this.isGeographySet) {
          this.setMessagePackageGeography({
            statekey: this.regionalCommitteeKey,
            location: this.committeeCountries.areas[1].value,
            location_name: this.committeeCountries.areas[1].text,
            type: 'RCommittee',
            user: this.userId,
            ordinal: 1,
          })
        }

        let add_update_result = await this.addUpdateAnnouncementMessage({
          include: this.include_state_elected_officers,
          membertype: this.g_recipients.substr(0, 1).toLowerCase(),
          messageid: this.announcementForm.msg_message_id,
        })
        this.setLoadingStatus(false)
        this.$swal({
          icon: add_update_result ? 'success' : 'error',
          text: add_update_result
            ? this.translations.tcAnnouncementSaved
            : this.translations.tcErrorAnnouncementNotSaved,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          if (add_update_result) {
            this.$router.push('/get-connected/stay-informed/announcements')
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleNextClick() {
      if (
        this.page === 1 &&
        (!this.selectedCommitteeCountries.areas || this.selectedCommitteeCountries.areas.length === 0) &&
        (!this.selectedUserCommittees.areas || this.selectedUserCommittees.areas.length === 0)
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcOneOrMoreRecipientsMustBeSelected,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          return
        })
      } else {
        if (this.page === 2) {
          if (this.checkedAudience === 0) {
            this.$swal({
              icon: 'error',
              text: this.translations.tcOneOrMoreOfficersMustBeSelected,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then(() => {
              return
            })
          }
        }
        this.page++
        if (this.page === 3) {
          document.documentElement.scrollTop = 0
        }
        if (this.page === 2) {
          if (!this.isCountrySelected) {
            this.page++
          } else {
            await this.filterItems(this.l_recipients, this.g_recipients)
          }
        }
      }
    },
    translateAreaItems(groupName, stringToMatch, translationLookup) {
      // Translate first element in Area list (IE, `All Members` or `All Countries`) ** needs matching entry in SiteCore
      if (!groupName || !stringToMatch) return false
      if (!translationLookup) translationLookup = `tc${this.removeSpacesAndSpecialChars(stringToMatch)}`
      const matchingIndex = this[groupName].areas.findIndex(group => {
        return group.text === stringToMatch
      })

      return this[groupName].areas[matchingIndex]
        ? this[groupName].areas[matchingIndex].text = this.translations[translationLookup]
        : false
    },
  },
  components: {
    AnnouncementReview: AnnouncementReview,
    AnnouncementTemplate: AnnouncementTemplate,
    audienceIcon: audienceIcon,
    gBackground: gBackground,
    GeoDis: GeoDis,
    GeographicAreas: GeographicAreas,
    iCalendar: iCalendar,
    iPaperclip: iPaperclip,
    iTooltip: iTooltip,
    messageIcon: messageIcon,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    reviewIcon: reviewIcon,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
// form template for add-template on AddCampAnnouncement.vue USE AddCampAnnouncement as include

.areas {
  margin: 20px 0;
}
.add-template {
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .btn-w190 {
    width: 190px;
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 1060
// 410
// ----
// 650

.item-1-load-enter-active {
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.add-regional-committee-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      text-transform: uppercase;
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
